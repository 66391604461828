import { V as _, S as u, a as c, E as f, Q as R, b as A, c as g, d, e as O, f as I } from "./constants-Bnl9ENcX.js";
let a, m, s;
l();
b();
function b() {
  window.addEventListener("popstate", l);
}
async function l() {
  const e = (
    // prod
    document.querySelector(
      "script[src*='/liveroom-client-element']"
    )
  );
  e == null || e.setAttribute("version", _);
  const { roomId: t, roomToken: o } = Y(e);
  if (t && o && (t != a || o != m)) {
    a = t, m = o, sessionStorage == null || sessionStorage.setItem(u, a), sessionStorage == null || sessionStorage.setItem(c, m), s && (s.remove(), s = void 0);
    const n = e == null ? void 0 : e.getAttribute("data-url");
    await U(
      n || "wss://liveroom.app/room",
      a,
      m
    );
  }
}
async function U(e, t, o) {
  if (!t || !o)
    return !1;
  await import("./liveroom-client-element.js"), console.log(`[Liveroom] Installed successfully (v${_})`), s = document.createElement(f), s.setAttribute("url", e), s.setAttribute("version", _), t && s.setAttribute("room_id", t), t && s.setAttribute("room_token", o);
  const n = w();
  n && s.setAttribute("user_name", n);
  const r = y();
  return r && s.setAttribute("user_color", r), document.body.appendChild(s), console.log("[Liveroom] Injected successfully"), !0;
}
function w() {
  const t = new URLSearchParams(window.location.search).get(R), o = sessionStorage == null ? void 0 : sessionStorage.getItem(
    A
  );
  return t || o || null;
}
function y() {
  const t = new URLSearchParams(window.location.search).get(g), o = sessionStorage == null ? void 0 : sessionStorage.getItem(
    d
  );
  return t || o || null;
}
function Y(e) {
  const t = new URLSearchParams(window.location.search), o = t.get(O), n = t.get(I), r = sessionStorage == null ? void 0 : sessionStorage.getItem(
    u
  ), i = sessionStorage == null ? void 0 : sessionStorage.getItem(
    c
  ), E = (e == null ? void 0 : e.getAttribute("data-roomid")) || null, S = (e == null ? void 0 : e.getAttribute("data-roomtoken")) || null;
  return {
    roomId: M(
      o || E || r
    ),
    roomToken: n || S || i
  };
}
function M(e) {
  const t = e == null ? void 0 : e.split(
    "https://meet.google.com/"
  ), o = t == null ? void 0 : t[1];
  return (o ? o.split("?")[0] : t == null ? void 0 : t[0]) || null;
}
